import React from 'react'
import { Link } from 'gatsby'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenGrabados from '../components/imageComponents/imagenGrabados'

const IndexPage = () => (
  <EnLayout>
    <SEO title='Engraving' />
    <Header siteTitle='ENGRABING' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>


          <div className='col-sm-12 col-md-5'>
            <ImagenGrabados />
          </div>

          <div className='col-sm-12 col-md-7  col-lg-7 conmargen'>

            <p> <b><span className="oro"> OR-PRINTER </span></b>  deliver to our customers 
               engraved in 24/48 hours for 1.75 mm engravings. 3mm, 7mm, brass and embossed.
            </p><p>
              <b><span className="oro"> OR-PRINTER </span></b> provides engravings:
              </p>
            <ul className='aplicaciones'>
              <li> Metallic </li>
              <li> Zinc </li>
              <li> Magnesium </li>
              <li> Polymer </li>
              <li> Brass </li>
            </ul>

            <p><Link className="maquinas" to="/Contact/">  Contact</Link> us  and we will advise you on the best option.
            We are suppliers of engravings for a wide list of active clients with extensive experience.

            </p>
          </div>
        </div>

      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
